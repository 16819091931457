<template>
  <div>
    <div class="card">
      <div class="card-header">
        <div class="card-title">Yeni Görev</div>
      </div>
      <div class="card-body p-0">
        <div class="border-top border-bottom p-1">
          <div class="form-group">
            <small class="text-sm text-uppercase font-semibold">Görev Tanımı</small>
            <v-select
              v-model="sf.task.task_definition_id"
              :options="task_todo"
              :reduce="(option) => option.value"
              :searchable="true"
              :clearable="false"
            />
          </div>
          <div class="form-group">
            <small class="text-sm text-uppercase font-semibold">Notlar</small>
            <textarea
              v-model="sf.task.task_notes"
              class="form-control"
              rows="7"
            ></textarea>
          </div>

          <div class="row">
            <div class="col-sm-4">
              <div class="form-group">
                <small class="text-sm text-uppercase font-semibold"
                  >Planlanan Tarih</small
                >
                <flat-pickr
                  class="form-control"
                  v-model="sf.task.planned_date"
                  :config="{
                    enableTime: true,
                    altFormat: 'd.m.Y H:m',
                    altInput: true,
                    dateFormat: 'Y-m-d H:m',
                    locale: 'tr',
                  }"
                />
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group">
                <small class="font-medium text-uppercase">Aciliyet Derecesi</small>
                <v-select
                  v-model="sf.task.priority_id"
                  :searchable="false"
                  :clearable="true"
                  :options="global.delivery_priority"
                  :reduce="(curr) => curr.value"
                />
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group">
                <small class="text-sm text-uppercase font-semibold">Görev Türü</small>
                <v-select
                  v-model="sf.task.task_type"
                  :options="task_types"
                  :reduce="(option) => option.value"
                  :searchable="false"
                  :clearable="false"
                />
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group">
                <small class="text-sm text-uppercase font-semibold">Görev Durumu</small>
                <v-select
                  v-model="sf.task.status"
                  :options="task_status"
                  :reduce="(option) => option.value"
                  :searchable="false"
                  :clearable="false"
                />
              </div>
            </div>
            <div v-if="false" class="col-sm-3">
              <div class="form-group">
                <small class="text-sm text-uppercase font-semibold"
                  >Kullanıcı</small
                >
                <v-select
                  v-model="datasource.filter.staff_id"
                  :options="datasource.users"
                  :reduce="(option) => option.user_id"
                  label="user_namesurname"
                  :searchable="true"
                  :clearable="true"
                />
              </div>
            </div>
            <div class="col-sm-2">
              <div class="form-group mt-2">
                <button class="btn btn-primary" @click="saveTask()">
                  Kaydet
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TaskDetailModal from "./TaskDetailModal.vue";
import StatusBadge from "@/views/common/StatusBadge.vue";
import { VueAutosuggest } from "vue-autosuggest";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import { tr } from "flatpickr/dist/l10n/tr.js";
import api from "@/service/api";
import utils from "@/service/utils";
import _ from "lodash";
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
import moment from "moment";
export default {
  components: {
    StatusBadge,
    VueAutosuggest,
    vSelect,
    flatPickr,
    TaskDetailModal,
  },
  data() {
    return {
      datasource: {
        users: [],
        tasks: [],
        task: null,
        table: {
          fields: [
            {
              key: "task_type",
              label: "Tür",
              sortable: true,
              selected: true,
            },
            {
              key: "task_name",
              label: "Görev",
              sortable: true,
              selected: true,
            },
            {
              key: "device_item",
              label: "Cihaz",
              sortable: true,
              selected: true,
            },
            {
              key: "staff_item",
              label: "Personel",
              sortable: true,
              selected: true,
            },
            {
              key: "date_item",
              label: "Tarih",
              sortable: false,
              tdClass: "minw14rem pr-0 pl-1",
              thClass: "minw14rem pr-0 pl-1",
            },
            { key: "status_item", label: "Durum", sortable: true },
          ],
          selected: null,
        },
      },
    };
  },
  methods: {
    ...mapActions(["saveTask"]),
    ...mapMutations(["clearTask"]),
    getUsers() {
      api.action("getUsers").then((q) => {
        if (q.success) {
          this.datasource.users = q.data;
        }
      });
    },
    filter() {
      let postData = this.datasource.filter;
      api.action("getTasks", { ...postData }).then((q) => {
        if (!q.success) {
          utils.notificationToast("warning", "Bilgi", q.message);
          return;
        }
        this.datasource.tasks = q.data;
      });
    },
    setTask(item) {
      item = item[0];
      if (item) {
        this.sf.task = item;
        if (item.device_item != null) {
          this.sf.device = item.device_item;
        }
        if (item.delivery_item != null) {
          this.sf.delivery = item.delivery_item;
        }
      }
      this.$bvModal.show("modal_task_detail");
    },
    rowClass(item, type) {
      if (!item || type !== "row") {
        return;
      }
    },
    moment(v) {
      return moment(v);
    },
  },
  created() {

    this.clearTask();
    this.getUsers();
    this.sf.task.staff_id = utils.getActiveUser().user_id;
    this.sf.task.task_definition_id = null;
    this.sf.task.priority_id = '1394';
    this.sf.task.task_type = 'task';
    this.sf.task.status = 'pending';
    this.datasource.filter.start_date = utils.moment().format("YYYY-MM-DD");
    this.datasource.filter.end_date = utils.moment().format("YYYY-MM-DD");
  },
  computed: {
    ...mapState({
      sf: (state) => state.sf,
      global: (state) => state.global,
    }),
    task_status() {
      let result = [];
      result.push(...this.global.task_status);
      return result;
    },
    task_types() {
      let result = [];
      result.push(...this.global.task_types);
      return result;
    },
    task_todo() {
      let result = [];
      result.push(...this.global.task_todo);
      return result;
    },
    list_service_task() {
      let result = [];
      return result;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>